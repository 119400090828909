.map-esri{
    width: 100%;
    height: 100%;
}

.map3d{
    width: 100%;
    height: 100%;
}

.smartshape{
    width: 100%;
    height: 100%;
}

.display-container {
    width: 100%;
    //height: 100%;
    display: flex;
    flex-direction: column;
}

.block {
    display: block;
}

.map-leaflet {
    flex-wrap: wrap;
    height: calc(100vh);
    &.map-hidden {
        height: 1px !important;
        overflow: hidden;
    }
}

.iframe-cesium-container {
    flex-wrap: wrap;
    .iframe {
        height: calc(100vh - 45px);
    }
    &.map-hidden {
        height: 0 !important;
        overflow: hidden;
        border: 0;
    }
}

.btn-3d {
    position: absolute;
    //&.active {
    //    right:129px;
    //    top: 52px;
    //}
    right: 71px;
    top: 56px;
    z-index: 21000;
}

.btn-home {
    position: absolute;
    right: 71px;
    top: 112px;
    z-index: 21000;
}

.search-result-container, .search-detail-container, .report-container {
    top: 60px;
    position: fixed;
    z-index: 500;
    left: 79px;
    height: calc(100% - 95px);
}

.report-container {
    display: flex;
    width: calc(100% - 150px);
}

.report-container-hidden {
    width: auto !important;
}

.report--body {
    background-color: #F3F3F3;
}

#cesiumContainer {
    height: 50%;
}